import React, { useState } from "react"
import SEO from "../components/seo"
import { css } from '@emotion/core';
import AltHeader from "../components/AltHeader"
import Textures from "../components/Textures"
import CaseStudyLinks from "../components/CaseStudyLinks"
import { useSpring, animated, config } from 'react-spring'

export default function CaseStudy(props) {
  const pages = [
    {
      url: "/work",
      name: "Work"
    },
    {
      url: "/about",
      name: "About"
    },
    {
      url: "/",
      name: "Home"
    }
  ]
  let cases = [
    {
      title: "Andy Hull",
      excerpt: "album artwork",
      slug: ""
    },
    {
      title: "Brandon Hatton",
      excerpt: "personal brand",
      slug: ""
    },
    {
      title: "Gathr Lab",
      excerpt: "rebrand",
      slug: ""
    },
    {
      title: "Lacroix",
      excerpt: "rebrand",
      slug: ""
    },
    {
      title: "Le Lush",
      excerpt: "new brand",
      slug: ""
    },
    {
      title: "MNTR",
      excerpt: "rebrand",
      slug: ""
    },
    {
      title: "20 Hour Brand Build",
      excerpt: "product",
      slug: ""
    }
  ]
  const currentCase = cases.shift()
  const [navOpen, setOpenState] = useState(false)

  const buttonState = useSpring({
    to: [{
      // opacity: navOpen ? 0 : 1,
      transform: navOpen ? "rotate(0deg)" : "rotate(-90deg)",
    }],
    config: config.stiff,
  });
  const showHide = useSpring({
    to: [
      {
        transform: navOpen ? "translateY(0px)" : "translateY(-100px)",
      },
      {
        display: navOpen ? "block" : "none",
      }
    ],
    // from: {display: navOpen ? "block" : "none"},
    config: config.stiff,
  });
  console.log(navOpen)

  return (
    <main>
      <SEO title="Case Studies" />
      <Textures/>
      <main className="min-h-screen w-screen bg-primary">
        <AltHeader pages={pages}/>
        <section className="flex flex-col items-center" style={{maxWidth: "710px"}}>
          <div className="relative p-6">
            <div className="w-full flex items-center justify-between lg:w-auto lg:inline">
              <CaseStudyLinks content={{title: currentCase.title, excerpt: currentCase.excerpt, slug: currentCase.slug}}/>
              <button
                type="button" 
                onClick={() => {navOpen ? setOpenState(false) : setOpenState(true)}}
                className="text-3xl border border-black rounded-full w-8 h-8 flex items-center justify-center"
                css={css`        
                  @media screen and (min-width: 1024px) {
                    display: none;
                  }
                `
              }>
                <div className="absolute w-3 bg-black" style={{height: "2px"}}></div>
                <animated.div className="absolute w-3 bg-black" style={buttonState}
                  css={css`        
                    height: 2px;
                    transform: rotate(-90deg);
                  `
                }>
                </animated.div>
              </button>
            </div>
            <animated.div
              style={{showHide}}
              className="w-full hidden lg:inline"
              css={css`        
                  @media screen and (min-width: 1024px) {
                    opacity: 0.6;
                  }
                `
              }>
              {cases.map((c,i) => {
                const title = c.title
                const excerpt = c.excerpt
                const slug = c.slug

                return (
                  <CaseStudyLinks key={i} content={{title: title, excerpt: excerpt, slug: slug}}/>
                )
              })}
            </animated.div>
          </div>
        </section>
        <section className="relative p-6 lg:p-24 pt-0">
          {/* {content.map((c,i) => {
            return (
              <img src={c.src} className="w-full object-cover mb-4"
                css={css`
                  height: 320px;
        
                  @media screen and (min-width: 1024px) {
                    height: auto;
                  }
                `
              }/>
            )
          })} */}
        </section>
      </main>
    </main>
  )
}
