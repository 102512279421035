import { React, useEffect, useState } from "react"
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Title = styled.h2`
  display: inline;
  font-size: 20px;
  font-weight: normal;
  transition: color 0.3s ease-in-out;
  line-height: 2;
  margin-right: 10px;

  @media screen and (min-width: 360px) {
    font-size: 22px;
  }

  @media screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 32px;
  }

  &:before {
    content: "⁎";
    font-size: 50px;
    line-height: 0;
    margin-right: 3px;

    @media screen and (min-width: 360px) {
      font-size: 62px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 80px;
    }
  }

  .title-excerpt {
    display: none;
    top: -10px;
    margin-left: 3px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: lighter;

    @media screen and (min-width: 360px) {
      display: inline-block;
    }

    @media screen and (min-width: 1024px) {
      top: -20px;
    }
  }
`

export default function CaseStudyLinks(props) {
  const title = props.content.title
  const excerpt = props.content.excerpt
  const slug = props.content.slug
  const inline = props.inline ? "inline" : ""

  const [isMobile, setIsMobile] = useState(null)

  const screenCheck = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    screenCheck()
    window.addEventListener('resize', () => {screenCheck()})
  })


  return (
    <Link
      to={`/work/${slug}`}
      css={css`
        display: block;
        z-index: 1;
        transition: transform 0.33s var(--ease-out-quart);
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        opacity: ${props.workPage ? '0' : '1'};
      
        @media screen and (min-width: 1024px) {
          display: ${inline};
        }
      `
    }>
      <Title>
        {title}
        <sup className="title-excerpt">{excerpt}</sup>
      </Title>
    </Link>
  )
}


